//
// Mixins / Panels
// --------------------------------------------------

@mixin pixel-panel-base($color, $background, $border) {
  border-color: $border !important;

  .panel-heading {
    background: $background;
    border-color: $border;
    color: $color;
  }

  .panel-heading-text a,
  &.panel-body-colorful a {
    color: $color;

    &:hover,
    &:focus {
      color: lighten($color, 10%);
    }
  }

  .panel-heading-text a,
  &.panel-body-colorful .panel-body a,
  &.panel-body-colorful .panel-footer a {
    text-decoration: underline;
  }

  .panel-footer { border-color: $border; }

  &.panel-body-colorful {
    &,
    .panel-body,
    .panel-footer {
      background: $background;
      color: $color;
    }

    hr,
    .panel-body {
      border-color: $border !important;
    }

    .text-muted { color: transparentize($color, .3) !important; }
  }
}

@mixin pixel-panel-variant($state, $color, $background, $border, $color-dark, $background-dark, $border-dark: $background-dark) {
  .panel-#{$state} {
    @include pixel-panel-base($color, $background, $border);

    &.panel-dark {
      @include pixel-panel-base($color-dark, $background-dark, $border-dark);

      .panel-heading-controls li.active a,
      .panel-heading-controls li.active span {
        &,
        &:hover,
        &:focus {
          color: $background-dark;
        }
      }

      &.panel-body-colorful {
        .panel-heading,
        .panel-footer,
        .panel-body,
        hr,
        table,
        table tr,
        table th,
        table td,
        .list-group-item {
          color: #fff;
          border-color: lighten($background-dark, 15%) !important;
        }
      }
    }
  }
}
