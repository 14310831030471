//
// Mixins / Navs
// --------------------------------------------------

@mixin nav-size($size, $padding, $font-size: null) {
  .nav-#{$size} > li > a {
    padding: $padding;

    font-size: $font-size;

    @content;
  }
}

@mixin nav-theme($color, $background, $border: darken($background, 5%)) {
  .nav-tabs > li.active > a,
  .nav-pills > li.active > a {
    &,
    &:hover,
    &:focus {
      color: $color;
      background: $background;
    }
  }

  .nav-tabs > li.active > a { border-bottom-color: $border !important; }
}
