//
// Notes
// --------------------------------------------------

.note {
  margin-bottom: 20px;
  padding: $note-padding;

  border-left: 3px solid $note-border;
  background: $note-background;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 10px;

    font-weight: 600;
  }

  p:last-child { margin-bottom: 0; }
}


// Colors
//

@include pixel-note-variant(success, $brand-success, $state-success-bg);
@include pixel-note-variant(danger, $brand-danger, $state-danger-bg);
@include pixel-note-variant(warning, $brand-warning, $state-warning-bg);
@include pixel-note-variant(info, $brand-info, $state-info-bg);
