//
// Utilities / Text
// --------------------------------------------------

.line-height-1 { line-height: 1 !important; }

// Alignment
//

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}


// Responsive alignment
//

.text-xs-left    { text-align: left !important; }
.text-xs-right   { text-align: right !important; }
.text-xs-center  { text-align: center !important; }

@media (min-width: $screen-sm-min) {
  .text-sm-left    { text-align: left !important; }
  .text-sm-right   { text-align: right !important; }
  .text-sm-center  { text-align: center !important; }
}

@media (min-width: $screen-md-min) {
  .text-md-left    { text-align: left !important; }
  .text-md-right   { text-align: right !important; }
  .text-md-center  { text-align: center !important; }
}

@media (min-width: $screen-lg-min) {
  .text-lg-left    { text-align: left !important; }
  .text-lg-right   { text-align: right !important; }
  .text-lg-center  { text-align: center !important; }
}

@media (min-width: $screen-xl-min) {
  .text-xl-left    { text-align: left !important; }
  .text-xl-right   { text-align: right !important; }
  .text-xl-center  { text-align: center !important; }
}


// Transformation
//

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }


// Weight and italics
//

.font-weight-light    { font-weight: 300 !important; }
.font-weight-normal   { font-weight: 400 !important; }
.font-weight-semibold { font-weight: 600 !important; }
.font-weight-bold     { font-weight: 700 !important; }
.font-weight-bolder   { font-weight: 900 !important; }
.font-italic          { font-style: italic !important; }


// Decoration
//

.text-underlined {
  text-decoration: underline !important;
}


// Sizes
//

.font-size-11 { font-size: 11px !important; }
.font-size-12 { font-size: 12px !important; }
.font-size-13 { font-size: 13px !important; }
.font-size-14 { font-size: 14px !important; }
.font-size-15 { font-size: 15px !important; }
.font-size-16 { font-size: 16px !important; }
.font-size-17 { font-size: 17px !important; }
.font-size-18 { font-size: 18px !important; }
.font-size-20 { font-size: 20px !important; }
.font-size-24 { font-size: 24px !important; }
.font-size-28 { font-size: 28px !important; }
.font-size-34 { font-size: 34px !important; }
.font-size-40 { font-size: 40px !important; }
.font-size-46 { font-size: 46px !important; }
.font-size-52 { font-size: 52px !important; }
