//
// Mixins / Tooltips
// --------------------------------------------------

@mixin pixel-tooltip-variant($state, $background){
  .tooltip-#{$state} {
    .tooltip-inner { background-color: $background; }

    &.top .tooltip-arrow,
    &.top-left .tooltip-arrow,
    &.top-right .tooltip-arrow {
      border-top-color: $background;
    }

    &.bottom .tooltip-arrow,
    &.bottom-left .tooltip-arrow,
    &.bottom-right .tooltip-arrow {
      border-bottom-color: $background;
    }

    &.right .tooltip-arrow { border-right-color: $background; }
    &.left .tooltip-arrow { border-left-color: $background; }
  }
}

@mixin tooltip-theme($background) {
  @include pixel-tooltip-variant(primary, $background);
}
