//
// Mixins / Tools
// --------------------------------------------------

@mixin arrow-down($size) {
  border-top: $size solid;
  border-right: $size solid transparent;
  border-bottom: none;
  border-left: $size solid transparent;
}

@mixin arrow-up($size) {
  border-top: none;
  border-right: $size solid transparent;
  border-bottom: $size solid;
  border-left: $size solid transparent;
}

@mixin arrow-left($size) {
  border-top: $size solid transparent;
  border-right: $size solid;
  border-bottom: $size solid transparent;
  border-left: none;
}

@mixin arrow-right($size) {
  border-top: $size solid transparent;
  border-right: none;
  border-bottom: $size solid transparent;
  border-left: $size solid;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
