//
// Mixins / Notes
// --------------------------------------------------

@mixin pixel-note-variant($state, $color, $background) {
  .note-#{$state} {
    border-color: $color;
    background: $background;

    h1, h2, h3, h4, h5, h6 {
      color: $color;
    }
  }
}
