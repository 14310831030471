//
// Mixins / Utilities
// --------------------------------------------------

@mixin pixel-text-color($color) {
  &,
  &:hover,
  &:focus,
  &:active,
  &[href],
  a {
    color: $color !important;
  }

  &[href],
  a {
    &:hover,
    &:focus,
    &:active {
      color: darken($color, 10%) !important;
    }
  }
}

@mixin pixel-bg-color($background, $color: #fff) {
  background: $background !important;
  border-color: desaturate(darken($background, 9%), 6%) !important;

  &[href]:hover { background: desaturate(darken($background, 3%), 6%) !important; }

  &.darken {
    background: desaturate(darken($background, 3%), 6%) !important;
    border-color: desaturate(darken($background, 12%), 6%) !important;

    &[href]:hover { background: desaturate(darken($background, 6%), 6%) !important; }
  }

  &.darker {
    background: desaturate(darken($background, 6%), 6%) !important;
    border-color: desaturate(darken($background, 14%), 6%) !important;

    &[href]:hover { background: desaturate(darken($background, 9%), 6%) !important; }
  }

  @include pixel-text-color($color);

  &[href],
  a {
    &:hover,
    &:focus,
    &:active {
      color: $color !important;
    }
  }

  * { border-color: lighten($background, 7%) !important; }

  .text-muted,
  .text-muted:hover,
  .text-muted:focus,
  .text-muted:active,
  .text-muted[href],
  .text-muted a {
    color: rgba(255, 255, 255, .6) !important;
  }
}

@mixin pixel-border-color($border) {
  border-color: $border !important;
}

@mixin pixel-colors($name, $color) {
  .bg-#{$name} {
    @include pixel-bg-color($color);
  }

  .text-#{$name} {
    @include pixel-text-color($color);
  }

  .border-#{$name} {
    @include pixel-border-color($color);
  }
}
