//
// Utilities / Floating
// --------------------------------------------------

.pull-xs-left  { float: left !important; }
.pull-xs-right { float: right !important; }
.pull-xs-none  { float: none !important; }

@media (min-width: $screen-sm-min) {
  .pull-sm-left  { float: left !important; }
  .pull-sm-right { float: right !important; }
  .pull-sm-none  { float: none !important; }
}

@media (min-width: $screen-md-min) {
  .pull-md-left  { float: left !important; }
  .pull-md-right { float: right !important; }
  .pull-md-none  { float: none !important; }
}

@media (min-width: $screen-lg-min) {
  .pull-lg-left  { float: left !important; }
  .pull-lg-right { float: right !important; }
  .pull-lg-none  { float: none !important; }
}

@media (min-width: $screen-xl-min) {
  .pull-xl-left  { float: left !important; }
  .pull-xl-right { float: right !important; }
  .pull-xl-none  { float: none !important; }
}
