//
// Plugins / Responsive bg
// --------------------------------------------------

.px-responsive-bg-container {
  position: relative;
  z-index: 0;

  transition: background .2s;

  background: rgba(255, 255, 255, 0) !important;
}

.px-responsive-bg,
.px-responsive-bg-overlay {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;

  overflow: hidden !important;

  margin: 0 !important;
  padding: 0 !important;
}

.px-responsive-bg {
  z-index: -999997;

  user-select: none;
  animation-name: px-responsive-bg-animation;
  animation-duration: .2s;

  animation-fill-mode: both;

  img {
    position: absolute;
    z-index: -999999;
    left: 0px;

    width: 100%;
    max-width: none;
    margin: 0px;
    padding: 0px;

    border: none;
  }
}

.px-responsive-bg-overlay { z-index: -999998; }

@include keyframes(px-responsive-bg-animation) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
