//
// Popovers
// --------------------------------------------------

.popover {
  border-width: $popover-border-width;
  box-shadow: none;

  .arrow:after { display: none; }

  &.top {
    margin-top: -$popover-arrow-width - 2px;

    .arrow {
      bottom: -$popover-arrow-width - $popover-border-width - 1px;

      border-top-color: inherit;
    }
  }

  &.bottom {
    margin-top: $popover-arrow-width + 2px;

    .arrow {
      top: -$popover-arrow-width - $popover-border-width - 1px;

      border-bottom-color: inherit;
    }
  }

  &.left {
    margin-left: -$popover-arrow-width - 2px;

    .arrow {
      right: -$popover-arrow-width - $popover-border-width - 1px;

      border-left-color: inherit;
    }
  }

  &.right {
    margin-left: $popover-arrow-width + 2px;

    .arrow {
      left: -$popover-arrow-width - $popover-border-width - 1px;

      border-right-color: inherit;
    }
  }
}

// Popover title
.popover-title {
  padding-top: 6px;
  padding-bottom: 6px;

  border-bottom-color: #ddd;
  border-radius: 0;

  font-weight: 600;

  background-color: rgba(0, 0, 0, .06);

  line-height: $line-height-base;
}


// Colorful popovers
//

.popover-dark.popover-colorful .popover-title { border-bottom-width: 2px; }


// Colors
//

@include pixel-popover-variant(warning,
  $state-warning-text, $state-warning-bg, $state-warning-border, // Light
  #fff, $brand-warning // Dark
);
@include pixel-popover-variant(danger,
  $state-danger-text, $state-danger-bg, $state-danger-border, // Light
  #fff, $brand-danger // Dark
);
@include pixel-popover-variant(success,
  $state-success-text, $state-success-bg, $state-success-border, // Light
  #fff, $brand-success // Dark
);
@include pixel-popover-variant(info,
  $state-info-text, $state-info-bg, $state-info-border, // Light
  #fff, $brand-info // Dark
);
